import React from 'react';

function Heating() {
  return (
    <div>
      <h1>Heating Services</h1>
      <p>Details about heating services...</p>
    </div>
  );
}

export default Heating;
